import React from 'react';
import { ROUTE_PATH } from '../../../routes/route-paths';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';

import { Resource } from '../../../hooks/useResources';
import { ResourceTile, ResourceTileSkeleton } from '../../../components/mwa-3.5-redesign/resource-tile';

interface MyProgramsProps {
  resources: Resource[] | undefined;
  isLoading: boolean;
  clientLogo?: string;
  isGlobal: boolean;
}

const MyPrograms: React.FC<MyProgramsProps> = ({ resources, isLoading, clientLogo, isGlobal }) => {
  return (
    <TileContainer
      title={isGlobal ? 'Resources' : 'My Programs'}
      subtitle={isGlobal ? 'National and local resources for you' : 'Resources from your organization'}
      isLoading={isLoading}
      clientLogo={!isGlobal ? clientLogo : undefined}
      childrenTiles={
        <>
          {resources
            ?.slice(0, 3)
            .map((resource) => (
              <ResourceTile
                key={resource.id}
                id={resource.id}
                image={resource.image_url}
                resourceName={resource.name}
                caption={resource.description}
              />
            ))}
        </>
      }
      skeletonTiles={[1, 2, 3].map((int) => (
        <ResourceTileSkeleton key={int} />
      ))}
      redirectText="See all resources »"
      redirectLink={isGlobal ? ROUTE_PATH.GLOBAL_RESOURCES : ROUTE_PATH.RESOURCES}
    />
  );
};

export default MyPrograms;
