import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';
import { PLUSPrescriptionBenefit } from '../../../assets';
import getPartnerResourceConfig from '../partner-resource-config';
import { Spinner } from '../../../components/common';
import { useExternalProviderPlan } from '../../../hooks/useExternalProviderPlans';
import { ResourceDetailView } from '../resource-detail';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';
import useAuth from '../../../hooks/useAuth';

export const PrescriptionBenefitPage: React.FC = () => {
  const navigate = useHistory();
  const { user, isLoading: userIsLoading } = useAuth();
  const { data: providerPlans, isLoading: externalPlanLoading } = useExternalProviderPlan();
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;
  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);

  // TODO: add this line in to get the ssoLinkUrl
  // const { data: ssoLinkUrl, isLoading: ssoLinkUrlPending } = useTelemedRedirect(user?.id || 0);
  const ssoLinkUrl = { redirect_url: 'https://member.recurohealth.com' };

  const name = '+Prescription Benefit';
  const description = '24/7 access to physicians for common medical concerns.';
  const longDescription = (
    <div className="flex flex-col gap-y-4">
      Your benefit includes access to approximately 65 generic urgent care prescriptions at no cost, or for home
      delivery of ongoing medications for treatment of chronic conditions. Pick up your urgent care medications from the
      pharmacy with a digital card which is conveniently located within your member portal, or order ongoing medications
      for chronic conditions by mail from the convenience of your home. Your prescription benefit is accepted at over
      70,000 retail pharmacy locations nationwide, including CVS, Kroger, Rite Aid, Walgreens, and Walmart.
      <div>
        COMMON MEDICATIONS:
        <ul>
          <li>Albuterol</li>
          <li>Amoxicillin</li>
          <li>Atorvastatin</li>
          <li>Benazepril</li>
          <li>Cephalexin</li>
          <li>Clindamycin</li>
          <li>Fluoxetine</li>
          <li>Lisinopril</li>
          <li>Metformin</li>
          <li>Prednisone</li>
          <li>And more</li>
        </ul>
      </div>
      <div>ONLINE ACCESS: Click the link above to access your Prescription Benefit.</div>
      <div>
        PHONE ACCESS: Locate a participating pharmacy by calling <div>800-699-3542</div>
      </div>
      <div>Disclaimer: Recuro Prescription Benefit is powered by WalkerRx.</div>
    </div>
  );

  if (externalPlanLoading || userIsLoading) return <Spinner />;

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={name}
        url={providerPlan?.provider === 'recuro' ? 'https://member.recurohealth.com' : ssoLinkUrl?.redirect_url}
        urlDescription={''}
        phoneNumber={providerPlan?.provider === 'recuro' ? '8006993542' : undefined}
        callButtonText={'Locate a Pharmacy'}
        ctaButtonText={'Access Your Prescription Benefit'}
        imageUrl={PLUSPrescriptionBenefit}
        tagNames={['Prescription Medications']}
        description={longDescription}
        clientLogoUrl={user?.caller_role.active_subscription?.package?.client?.comms_logo_file_url}
        partnerResourceConfig={partnerResourceConfig}
        recuro={providerPlan?.provider === 'recuro'}
      />
      <BackgroundStripe />
    </div>
  );
};
