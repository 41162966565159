import { FilterIcon } from '@kindlyhuman/component-library';
import React from 'react';
import { Link } from 'react-router-dom';
import { ExitIcon } from '../common/svgs';

interface TileContainerProps {
  title: string;
  subtitle?: string;
  bgColor?: string;
  dataTestId?: string;
  redirectText?: string;
  redirectLink?: string;
  isLoading?: boolean;
  skeletonTiles?: React.ReactNode;
  childrenTiles: React.ReactNode;
  clientLogo?: string;
  horizontal?: boolean;
  setSearchFilterOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  searchContainer?: boolean;
  handleClose?: () => void;
  titleSkeletons?: boolean;
}

const TileContainer: React.FC<TileContainerProps> = ({
  title,
  subtitle,
  bgColor = 'bg-[#e6e6e6]',
  dataTestId,
  redirectText,
  redirectLink,
  isLoading,
  skeletonTiles,
  childrenTiles,
  clientLogo,
  horizontal,
  setSearchFilterOpen,
  searchContainer,
  handleClose,
  titleSkeletons,
}) => {
  return (
    <div className={`${!searchContainer && 'w-90 min-w-90 h-full -z-10 bg-white rounded-[10px]'}`}>
      <div
        data-testid={dataTestId}
        className={`h-full ${!horizontal && 'w-90 min-w-90'} ${bgColor} md:max-w-[1080px] rounded-[10px] flex flex-col relative select-none`}
      >
        {clientLogo && (
          <div className={`w-24 h-12 bg-white absolute right-4 top-0 flex justify-center`}>
            <img src={clientLogo} alt="" />
            <div
              className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[40px] border-l-transparent border-r-[40px] border-r-transparent border-b-[6px] border-b-[${bgColor}] z-0`}
            />
          </div>
        )}
        {handleClose && (
          <ExitIcon
            data-testid="video-card-exit-icon"
            width={24}
            height={24}
            color={'#000'}
            className="cursor-pointer absolute right-4 top-6 flex justify-center"
            onClick={handleClose}
          />
        )}
        <div className={`flex ${searchContainer ? 'md:-mx-8' : 'px-3'} pt-2 justify-between`}>
          {titleSkeletons ? (
            <div className="flex flex-col animate-pulse w-full gap-2">
              <div className="bg-gray-300 h-8 w-1/2"></div>
              <div className="bg-gray-300 h-6 w-3/4"></div>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="text-2xl font-textaBlack leading-normal">{title}</div>
              {subtitle && <div className="text-sm font-thin opacity-70 font-texta leading-normal">{subtitle}</div>}
            </div>
          )}
          {setSearchFilterOpen && (
            <button onClick={() => setSearchFilterOpen(true)} className="p-3 pr-0 rounded-lg">
              <FilterIcon className="h-6 w-6 text-white cursor-pointer transform rotate-90" />
            </button>
          )}
        </div>
        <div
          className={`flex ${!horizontal && 'flex-col'} ${searchContainer ? 'max-w-7xl md:px-6 -mx-2 md:mx-0' : 'px-3'} py-2 gap-3`}
        >
          {isLoading ? skeletonTiles : childrenTiles}
          {redirectLink && (
            <div className="flex justify-end py-3">
              <Link to={redirectLink} className="text-md font-medium font-textaMedium antialiased leading-normal">
                {redirectText}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TileContainer;
