import { Button } from '@kindlyhuman/component-library';
import { Resource } from '../../../hooks/useResources';
import { Link } from 'react-router-dom';
import { Tag } from '../../../components/common/tag';

export interface ResourceCardProps extends Pick<Resource, 'name' | 'description' | 'image_url' | 'tag_names'> {
  link: string;
  challenge_areas?: number[] | null;
}

export const ResourceCard = ({ link, ...resource }: ResourceCardProps) => {
  return (
    <div className="flex flex-col h-[720px] w-90 bg-[#f6f6f6] select-none rounded-[10px] font-['Manrope'] p-4 pb-0">
      <Link to={link}>
        <img className="aspect-[1/1] w-full self-center mt-2 rounded-lg" alt="resource logo" src={resource.image_url} />
      </Link>
      <div className="flex flex-col grow justify-between">
        <div>
          <Link to={link}>
            <div className="text-[#240089] text-2xl font-extrabold leading-7 mt-4">{resource.name}</div>
          </Link>
          {resource.tag_names && (
            <div className="flex justify-between pt-4">
              <div className="flex gap-x-2 gap-y-2 flex-wrap">
                {resource.tag_names?.map((tagName) => {
                  return <Tag tag={tagName} />;
                })}
              </div>
            </div>
          )}
          <div className="text-[#222833] line-clamp-5 text-base leading-[22.5px] mt-4">{resource.description}</div>
        </div>
        <Link to={link}>
          <Button
            className="w-32 mb-8 whitespace-nowrap px-20 text-[#2ee5da] bg-[#081d40] border-[#081d40] hover:bg-[#081d40] hover:bg-opacity-70"
            variant="primary"
          >
            Learn More
          </Button>
        </Link>
      </div>
    </div>
  );
};
