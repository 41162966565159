import { Button, MobileHeader, LeftArrowWithCircle, SpinnerIcon } from '@kindlyhuman/component-library';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import './welcome-page.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import welcomeSlide1 from '../../assets/images/welcome-slide-1.jpg';
import welcomeSlide2 from '../../assets/images/welcome-slide-2.jpg';
import welcomeSlide3 from '../../assets/images/welcome-slide-3.jpg';
import welcomeSlide4 from '../../assets/images/welcome-slide-4.jpg';
import welcomeSlide5 from '../../assets/images/welcome-slide-5.jpg';
import welcomeSlide6 from '../../assets/images/welcome-slide-6.jpg';
import { EffectCreative } from 'swiper';
import { HTMLAttributes, ReactNode, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { twMerge } from 'tailwind-merge';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import { useClientCode } from '../../hooks/useClientCode';
import useAuth from '../../hooks/useAuth';
import { LoginButton } from '../../components/common/login-button';

const Slide: React.FC<{
  img: any;
  title: ReactNode;
  subtitle: ReactNode;
  description: string;
}> = ({ img, title, subtitle, description }) => {
  return (
    <div
      className="
        cursor-pointer pointer-events-none w-[335px] space-y-4
        md:cursor-auto lg:w-[470px] md:space-y-8
      "
    >
      <img className="rounded-md" src={img} alt="Welcome Slide" />
      <div className="space-y-2 md:space-y-4 md:space-x-2">
        <div className="text-violet-950 text-2xl font-bold leading-relaxed md:leading-loose md:text-3xl">
          <div className="md:pb-2">{title}</div>
          <div>{subtitle}</div>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="text-gray-800 text-base font-normal leading-normal"
        ></div>
      </div>
    </div>
  );
};

interface NavigationDotIconProps extends HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
}

const NavigationDotIcon = ({ isActive, className, ...props }: NavigationDotIconProps) => (
  <div
    {...props}
    className={twMerge(
      'w-3 h-3 rounded-full cursor-pointer',
      isActive ? 'bg-primary shadow-md shadow-[#25008a] border border-primary' : 'bg-neutral-200',
      className,
    )}
  />
);

const WelcomePage = (): JSX.Element => {
  const history = useHistory();
  const {
    data: client,
    isLoading: clientIsLoading,
    fetchStatus,
  } = useClientCode({
    partnerCode: sessionStorage.getItem('clientCode') || undefined,
  });
  const [activeSlide, setActiveSlide] = useState(0);
  const { user, isKeycloakEnabled, keycloak } = useAuth();

  const mobileSwiperRef = useRef<SwiperRef | undefined>();
  const desktopSwiperRef = useRef<SwiperRef | undefined>();

  /*
  This section is set up for the member pay addition. This is currently not used as we don't send the user to member pay until we turn it on
  */
  // const clientCode = sessionStorage.getItem('clientCode');
  // const { data: subscriptions } = useSubscriptions(clientCode);
  // const isMemberPay = clientCode && subscriptions?.length;

  const isSSO = user?.caller_role.active_subscription?.package.client.sso_configuration?.collect_password === false;

  const getStartedLink = isSSO ? ROUTE_PATH.ONBOARDING : ROUTE_PATH.SIGNUP;

  if (user) {
    const isOnboarded = !user?.is_partial;
    isOnboarded && history.push(ROUTE_PATH.HOME);
  }
  const slides = [
    <Slide
      title="Hello!"
      subtitle="We’re glad you’re here."
      description="Kindly Human offers support and guidance for the everyday challenges we all face. You’re never alone in whatever you are facing."
      img={welcomeSlide1}
    />,
    <Slide
      title="We’re here to help."
      subtitle="Get started today."
      description="Select the topic that suits your needs and receive recommendations for connections and resources – immediately."
      img={welcomeSlide2}
    />,
    <Slide
      title="Hear from voices of "
      subtitle="experience."
      description="Our peers have been there. Listen to short stories from others who have experienced something similar to what you’re facing"
      img={welcomeSlide3}
    />,
    <Slide
      title="Connect now."
      subtitle=""
      description="Connect with a peer who is trained to offer support and empathy - anonymously. You can schedule a call at your convenience - even the same day."
      img={welcomeSlide4}
    />,
    <Slide
      title="Find the right resource."
      subtitle=""
      description="Connect with a resource or group specific to your situation. Explore and discover the right resource from our library of support."
      img={welcomeSlide5}
    />,
    <Slide
      title="Start. Hear. Today!"
      subtitle=""
      description="Create your account and be matched with peers you can connect with as soon as today. Say hello to a Kindly Human!"
      img={welcomeSlide6}
    />,
  ];

  if (client) {
    slides.unshift(
      <Slide
        title={'Welcome,'}
        subtitle={client.client.name}
        description={client.client.landing_page_content}
        img={client.client.logo_background_file_url}
      />,
    );
  }

  return (
    <>
      <MobileHeader className="md:hidden" />
      <div className="grid w-screen grid-cols-1 md:grid-cols-2 md:h-screen">
        <WelcomeSlogan className="hidden md:block" />
        <div className="flex flex-col items-center justify-center pb-16 md:pb-0">
          <div className="w-full">
            {clientIsLoading && fetchStatus !== 'idle' ? (
              <SpinnerIcon />
            ) : (
              <>
                <Swiper
                  className="hidden md:block"
                  // @ts-ignore
                  ref={desktopSwiperRef}
                  simulateTouch={false}
                  grabCursor={false}
                  onSlideChange={(swiper) => {
                    setActiveSlide(swiper.activeIndex);
                  }}
                >
                  {slides.map((item, index) => (
                    <SwiperSlide key={index}>{item}</SwiperSlide>
                  ))}
                </Swiper>
                <Swiper
                  className="mySwiper md:hidden"
                  // @ts-ignore
                  ref={mobileSwiperRef}
                  onSlideChange={(swiper) => {
                    setActiveSlide(swiper.activeIndex);
                  }}
                  simulateTouch
                  grabCursor
                  effect="creative"
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ['100%', 0, 0],
                    },
                  }}
                  modules={[EffectCreative]}
                >
                  {slides.map((item, index) => (
                    <SwiperSlide key={index}>{item}</SwiperSlide>
                  ))}
                </Swiper>
              </>
            )}
            {activeSlide < slides.length - 1 ? (
              <div
                className="
                      hidden max-w-[355px] mx-auto items-center justify-between
                      md:flex lg:max-w-[470px]
                    "
              >
                <div className="space-x-2">
                  <button
                    disabled={activeSlide === 0}
                    className={`rotate-180 ${activeSlide === 0 ? 'opacity-50' : ''}`}
                    onClick={() => {
                      mobileSwiperRef?.current?.swiper?.slideTo(activeSlide - 1);
                      desktopSwiperRef?.current?.swiper?.slideTo(activeSlide - 1);
                    }}
                  >
                    <LeftArrowWithCircle />
                  </button>
                  {activeSlide < slides.length - 1 && (
                    <button
                      onClick={() => {
                        mobileSwiperRef?.current?.swiper?.slideTo(activeSlide + 1);
                        desktopSwiperRef?.current?.swiper?.slideTo(activeSlide + 1);
                      }}
                    >
                      <LeftArrowWithCircle />
                    </button>
                  )}
                </div>
                <Link className="text-primary text-sm font-bold leading-tight" to={getStartedLink}>
                  <Button variant="primary">Get Started</Button>
                </Link>
              </div>
            ) : (
              <div className="hidden mx-auto items-center justify-center md:flex">
                <Link to={getStartedLink}>
                  <Button variant="primary">Get started</Button>
                </Link>
              </div>
            )}
            <div className="flex items-center gap-y-8 flex-col md:hidden">
              <div className="flex justify-between w-40">
                {slides.map((_, index) => (
                  <NavigationDotIcon
                    key={index}
                    isActive={index === activeSlide}
                    onClick={() => {
                      mobileSwiperRef?.current?.swiper?.slideTo(index);
                      desktopSwiperRef?.current?.swiper?.slideTo(index);
                    }}
                  />
                ))}
              </div>
              <Link to={getStartedLink}>
                <Button variant="primary">Get started</Button>
              </Link>
            </div>
            {!isSSO && (
              <div className="flex justify-center items-center mt-8 gap-3">
                <LoginButton />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
