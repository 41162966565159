import React from 'react';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';

import { Resource } from '../../../hooks/useResources';
import { HorizontalScrollSection } from '../../../components/common/horizontal-scroll';
import { ResourceTile, ResourceTileSkeleton } from '../../../components/mwa-3.5-redesign/resource-tile';
import { ItemsNotFoundCTA } from '../../../components/mwa-3.5-redesign/items-not-found';

interface ResourceRecommendationsProps {
  recommendedResources: Resource[] | undefined;
  isLoading: boolean;
  setSearchFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResourceRecommendations: React.FC<ResourceRecommendationsProps> = ({
  recommendedResources,
  isLoading,
  setSearchFilterOpen,
}) => {
  const hasResults = recommendedResources && recommendedResources.length === 0;
  return (
    <div className="mb-4 w-full max-w-7xl md:px-8">
      <TileContainer
        title="Just For You"
        subtitle="Program and Resource Recommendations"
        childrenTiles={
          hasResults ? (
            <div></div>
          ) : (
            <HorizontalScrollSection
              className=""
              showNavigationButton
              isLoading={isLoading}
              children={recommendedResources
                ?.slice(0, 20)
                .map((resource: Resource) => (
                  <ResourceTile
                    key={resource.id}
                    id={resource.id}
                    image={resource.image_url}
                    resourceName={resource.name}
                    caption={resource.description}
                    tagNames={resource.tag_names}
                    horizontal
                  />
                ))}
            />
          )
        }
        skeletonTiles={
          <HorizontalScrollSection
            className=""
            showNavigationButton
            isLoading={isLoading}
            children={[...Array(6).keys()].map((int) => (
              <ResourceTileSkeleton key={int} horizontal />
            ))}
          />
        }
        isLoading={isLoading}
        bgColor=""
        horizontal
        searchContainer
      />
      {hasResults && (
        <ItemsNotFoundCTA className="self-start pb-6">
          Your search & filters resulted in no matches. Broaden your search or update your{' '}
          <button
            onClick={() => setSearchFilterOpen(true)}
            className="bg-none border-none text-blue-500 underline cursor-pointer"
          >
            filters
          </button>{' '}
          for more results.
        </ItemsNotFoundCTA>
      )}
    </div>
  );
};

export default ResourceRecommendations;
