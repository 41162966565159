import { SVGAttributes } from 'react';

function FramesOfMindIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="251" height="216" fill="none" viewBox="0 0 251 216" {...props}>
      <path
        fill="#FFE5F2"
        d="M78.5 2.31a8 8 0 018 0l26.641 15.38a8 8 0 014 6.929V55.38a8 8 0 01-4 6.928L86.5 77.691a8 8 0 01-8 0L51.859 62.309a8 8 0 01-4-6.928V24.62a8 8 0 014-6.928L78.5 2.309z"
      ></path>
      <path
        fill="#FF0083"
        fillOpacity="0.1"
        d="M80 17.732a6 6 0 016 0l14.785 8.536a6 6 0 013 5.196v17.072a6 6 0 01-3 5.196L86 62.268a6 6 0 01-6 0l-14.785-8.536a6 6 0 01-3-5.196V31.464a6 6 0 013-5.196L80 17.732z"
      ></path>
      <path
        fill="#FF0083"
        fillRule="evenodd"
        d="M79.833 39.667a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667zM77.333 43h5a3.333 3.333 0 013.334 3.333c0 .92-.746 1.667-1.667 1.667h-8.333c-.92 0-1.667-.746-1.667-1.667A3.333 3.333 0 0177.333 43z"
        clipRule="evenodd"
        opacity="0.12"
      ></path>
      <path
        fill="#FF0083"
        fillRule="evenodd"
        d="M76.917 36.083a2.833 2.833 0 115.666 0 2.833 2.833 0 01-5.666 0zm2.833-3.833a3.833 3.833 0 100 7.667 3.833 3.833 0 000-7.667zm5 0a.5.5 0 000 1 2.833 2.833 0 110 5.667.5.5 0 100 1 3.833 3.833 0 000-7.667zm-6.667 11a2.833 2.833 0 00-2.833 2.833c0 .645.522 1.167 1.167 1.167h6.666c.645 0 1.167-.522 1.167-1.167a2.833 2.833 0 00-2.833-2.833h-3.334zm7.167 2.833c0 1.197-.97 2.167-2.167 2.167h-6.666a2.167 2.167 0 01-2.167-2.167 3.833 3.833 0 013.833-3.833h3.334a3.833 3.833 0 013.833 3.833zm1.167-3.833a.5.5 0 100 1h1.666a2.833 2.833 0 012.834 2.833c0 .645-.523 1.167-1.167 1.167h-3.333a.5.5 0 100 1h3.333c1.197 0 2.167-.97 2.167-2.167a3.833 3.833 0 00-3.834-3.833h-1.666z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F5E5FC"
        d="M160.5 2.31a8 8 0 018 0l26.641 15.38a8 8 0 014 6.929V55.38a8 8 0 01-4 6.928L168.5 77.691a8 8 0 01-8 0l-26.641-15.382a8 8 0 01-4-6.928V24.62a8 8 0 014-6.928L160.5 2.309z"
      ></path>
      <path
        fill="#9B00E3"
        fillOpacity="0.1"
        d="M162 17.732a6 6 0 016 0l14.785 8.536a6 6 0 013 5.196v17.072a6 6 0 01-3 5.196L168 62.268a6 6 0 01-6 0l-14.785-8.536a6 6 0 01-3-5.196V31.464a6 6 0 013-5.196L162 17.732z"
      ></path>
      <rect width="15" height="15" x="157.5" y="32.5" fill="#9B00E3" opacity="0.12" rx="7.5"></rect>
      <path
        fill="#9B00E3"
        fillRule="evenodd"
        d="M165.25 32.25a8 8 0 100 16 8 8 0 000-16zm7 8a7 7 0 11-14 0 7 7 0 0114 0zm-9.934 2.2a.5.5 0 00-.8.6 4.662 4.662 0 003.734 1.867 4.66 4.66 0 003.733-1.867.5.5 0 10-.799-.6 3.661 3.661 0 01-2.934 1.467 3.661 3.661 0 01-2.934-1.467zm1.267-3.867a.833.833 0 11-1.666 0 .833.833 0 011.666 0zm4.167.834a.833.833 0 100-1.667.833.833 0 000 1.667z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#E5F6F8"
        d="M161.5 138.309a8 8 0 018 0l26.641 15.382a8 8 0 014 6.928v30.762a8 8 0 01-4 6.928L169.5 213.691a8 8 0 01-8 0l-26.641-15.382a8 8 0 01-4-6.928v-30.762a8 8 0 014-6.928l26.641-15.382z"
      ></path>
      <path
        fill="#00A7BD"
        fillOpacity="0.1"
        d="M163 153.732a5.999 5.999 0 016 0l14.785 8.536a6.002 6.002 0 013 5.196v17.072a6.002 6.002 0 01-3 5.196L169 198.268a5.999 5.999 0 01-6 0l-14.785-8.536a6.002 6.002 0 01-3-5.196v-17.072a6.002 6.002 0 013-5.196L163 153.732z"
      ></path>
      <rect width="16.667" height="12.5" x="157.667" y="171" fill="#00A7BD" opacity="0.12" rx="4"></rect>
      <path
        fill="#00A7BD"
        fillRule="evenodd"
        d="M163.294 170.584a2.834 2.834 0 015.579 0l-.685-.001h-4.209l-.685.001zm-1.013.012a3.833 3.833 0 017.605 0c.284.007.544.019.782.037.655.05 1.188.153 1.673.382a4.509 4.509 0 012.144 2.144c.229.485.332 1.018.382 1.673.05.646.05 1.449.05 2.48V177.355c0 1.031 0 1.834-.05 2.479-.05.655-.153 1.189-.382 1.673a4.505 4.505 0 01-2.144 2.144c-.485.229-1.018.333-1.673.383-.646.049-1.449.049-2.48.049h-4.209c-1.032 0-1.835 0-2.48-.049-.655-.05-1.189-.154-1.673-.383a4.5 4.5 0 01-2.144-2.144c-.229-.484-.332-1.018-.383-1.673-.049-.645-.049-1.448-.049-2.479v-.043c0-1.031 0-1.834.049-2.48.051-.655.154-1.188.383-1.673a4.505 4.505 0 012.144-2.144c.484-.229 1.018-.332 1.673-.382.238-.018.497-.03.782-.037zm-4.031 6.737c0 1.057 0 1.822.047 2.425.045.597.134.994.289 1.322a3.498 3.498 0 001.668 1.667c.327.155.724.244 1.322.29.602.046 1.367.046 2.424.046h4.167c1.057 0 1.821 0 2.424-.046.597-.046.994-.135 1.322-.29a3.502 3.502 0 001.668-1.667c.155-.328.243-.725.289-1.322.046-.603.047-1.368.047-2.425 0-.583 0-1.076-.008-1.503a23.531 23.531 0 01-2.167.734c-.543.155-1.158.311-1.825.446v.74a.5.5 0 01-1 0v-.561c-.893.138-1.851.228-2.834.228-.982 0-1.941-.09-2.833-.228v.561a.5.5 0 01-1 0v-.74a24.034 24.034 0 01-3.992-1.18c-.008.427-.008.92-.008 1.503zm11.667-1.344v-.739a.5.5 0 00-1 0v.926a17.75 17.75 0 01-2.834.241c-.981 0-1.941-.096-2.833-.241v-.926a.5.5 0 00-1 0v.739a22.64 22.64 0 01-3.257-.947 14.637 14.637 0 01-.467-.183l-.119-.05-.029-.012-.007-.003-.001-.001-.078.174.078-.174a.778.778 0 00-.062-.023c.049-.521.135-.881.278-1.183a3.504 3.504 0 011.668-1.668c.327-.155.724-.243 1.322-.289.602-.046 1.367-.047 2.424-.047h4.167c1.057 0 1.821.001 2.424.047.597.046.994.134 1.322.289a3.509 3.509 0 011.668 1.668c.142.302.229.662.277 1.183-.02.007-.041.014-.061.023l.077.174-.077-.174-.001.001-.007.003-.029.012-.12.05a22.59 22.59 0 01-3.723 1.13z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#E5F9EA"
        d="M79.5 138.309a8 8 0 018 0l26.641 15.382a8 8 0 014 6.928v30.762a8 8 0 01-4 6.928L87.5 213.691a8 8 0 01-8 0l-26.641-15.382a8 8 0 01-4-6.928v-30.762a8 8 0 014-6.928L79.5 138.309z"
      ></path>
      <path
        fill="#00C431"
        fillOpacity="0.1"
        d="M81 153.732a5.999 5.999 0 016 0l14.785 8.536a6.002 6.002 0 013 5.196v17.072a6.002 6.002 0 01-3 5.196L87 198.268a5.999 5.999 0 01-6 0l-14.785-8.536a6 6 0 01-3-5.196v-17.072a6 6 0 013-5.196L81 153.732z"
      ></path>
      <path
        fill="#00C431"
        d="M92.333 178.7v-2.067c0-1.68 0-2.52-.327-3.162a3.004 3.004 0 00-1.31-1.311c-.642-.327-1.483-.327-3.163-.327H75.667v6.867c0 1.68 0 2.52.327 3.162a3.004 3.004 0 001.31 1.311c.642.327 1.483.327 3.163.327h7.066c1.68 0 2.52 0 3.162-.327a3.004 3.004 0 001.311-1.311c.327-.642.327-1.482.327-3.162z"
        opacity="0.12"
      ></path>
      <path
        fill="#00C431"
        fillRule="evenodd"
        d="M87.156 169.276c-.308-.026-.704-.026-1.273-.026h-8.466a1.167 1.167 0 100 2.333H88.583a12.194 12.194 0 00-.025-.906c-.025-.302-.071-.476-.138-.608a1.503 1.503 0 00-.656-.656c-.131-.067-.305-.113-.608-.137zm-9.74 3.307c-.429 0-.83-.125-1.166-.34v6.707c0 .848 0 1.455.04 1.93.038.469.11.766.232 1.005.24.47.623.853 1.093 1.093.239.121.536.194 1.005.233.475.039 1.082.039 1.93.039h7.067c.848 0 1.454 0 1.93-.039.469-.039.766-.112 1.005-.233.47-.24.852-.623 1.092-1.093.122-.239.195-.536.233-1.005.02-.231.03-.492.034-.797H90.75a2.167 2.167 0 110-4.333h1.161a12.491 12.491 0 00-.034-.796c-.038-.47-.111-.767-.233-1.006a2.503 2.503 0 00-1.092-1.092c-.239-.122-.536-.195-1.006-.233-.467-.039-1.061-.04-1.888-.04H77.417zm15.5 3.637a18.22 18.22 0 00-.042-1.348c-.043-.531-.133-.975-.339-1.378a3.499 3.499 0 00-1.53-1.529c-.402-.206-.846-.296-1.377-.339l-.045-.004c0-.403-.005-.742-.029-1.026-.03-.365-.092-.685-.243-.981a2.504 2.504 0 00-1.093-1.093c-.296-.15-.616-.213-.98-.243-.354-.029-.791-.029-1.333-.029h-8.488c-1.197 0-2.167.97-2.167 2.167v8.555c0 .821 0 1.468.043 1.989.043.531.133.975.338 1.378a3.501 3.501 0 001.53 1.53c.403.205.847.295 1.378.338.52.043 1.168.043 1.989.043H87.639c.821 0 1.468 0 1.989-.043.531-.043.975-.133 1.378-.338a3.501 3.501 0 001.53-1.53c.205-.403.295-.847.338-1.378a18.22 18.22 0 00.043-1.378v-.497-2.225-.611a.5.5 0 00-.001-.03zm-1 .53H90.75a1.167 1.167 0 100 2.333h1.167v-2.296"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FEF7E6"
        d="M36.5 71.31a8 8 0 018 0l26.641 15.38a8 8 0 014 6.929v30.762a8 8 0 01-4 6.928L44.5 146.691a8 8 0 01-8 0L9.859 131.309a8 8 0 01-4-6.928V93.619a8 8 0 014-6.928L36.5 71.309z"
      ></path>
      <path
        fill="#FCB205"
        fillOpacity="0.1"
        d="M38 86.732a6 6 0 016 0l14.785 8.536a6 6 0 013 5.196v17.072a6 6 0 01-3 5.196L44 131.268a5.999 5.999 0 01-6 0l-14.785-8.536a6 6 0 01-3-5.196v-17.072a6 6 0 013-5.196L38 86.732z"
      ></path>
      <path
        fill="#FCB205"
        d="M34.522 109.874l5.916 6.051a.788.788 0 001.125 0l5.916-6.051a3.932 3.932 0 10-5.624-5.498l-.855.874-.855-.874a3.932 3.932 0 10-5.623 5.498z"
        opacity="0.12"
      ></path>
      <path
        fill="#FCB205"
        fillRule="evenodd"
        d="M40.018 105.314a3.433 3.433 0 00-4.909 4.799l5.916 6.051a.287.287 0 00.41 0l5.916-6.051a3.432 3.432 0 10-4.908-4.799l-.855.874a.5.5 0 01-.715 0l-.855-.874zm8.048-.699a4.432 4.432 0 010 6.197l-5.916 6.051a1.287 1.287 0 01-1.84 0l-5.916-6.051a4.432 4.432 0 116.339-6.197l.497.508.498-.508a4.431 4.431 0 016.338 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#E5EEFF"
        d="M199 71.31a8 8 0 018 0l26.641 15.38a8 8 0 014 6.929v30.762a8 8 0 01-4 6.928L207 146.691a8 8 0 01-8 0l-26.641-15.382a8 8 0 01-4-6.928V93.619a8 8 0 014-6.928L199 71.309z"
      ></path>
      <path
        fill="#005EFF"
        fillOpacity="0.1"
        d="M200 86.732a6 6 0 016 0l14.785 8.536a6 6 0 013 5.196v17.072a6.002 6.002 0 01-3 5.196L206 131.268a5.999 5.999 0 01-6 0l-14.785-8.536a6.002 6.002 0 01-3-5.196v-17.072a6 6 0 013-5.196L200 86.732z"
      ></path>
      <path
        fill="#005EFF"
        d="M206.333 116.5a5 5 0 115-5c0 .515-.119 1.029-.284 1.495-.305.863-.458 1.295-.486 1.485-.079.525-.054.692.179 1.17.083.173.253.398.591.85h-5z"
        opacity="0.12"
      ></path>
      <path
        fill="#005EFF"
        fillRule="evenodd"
        d="M195.25 108.417a6.167 6.167 0 0111.963-2.11 5.5 5.5 0 10-.796 10.943h5a.5.5 0 00.397-.804c-.355-.472-.482-.647-.539-.764-.113-.232-.148-.337-.162-.425-.013-.088-.011-.198.027-.453.007-.045.037-.159.12-.406.078-.234.191-.553.344-.986.177-.5.313-1.073.313-1.662a5.503 5.503 0 00-3.566-5.15 7.167 7.167 0 00-14.101 1.817c0 .768.161 1.522.373 2.185.173.541.301.941.39 1.234.093.304.134.462.145.533.062.384.073.578.049.749-.024.171-.088.355-.253.706-.075.161-.232.399-.616.976a.5.5 0 00.412.783h5a.5.5 0 000-1h-4.076c.08-.129.141-.238.186-.335.171-.365.292-.663.338-.991.046-.328.012-.648-.052-1.046a6.126 6.126 0 00-.176-.666c-.092-.301-.222-.708-.393-1.243l-.001-.004c-.192-.6-.326-1.247-.326-1.881zm15.667 3.333a4.5 4.5 0 10-4.5 4.5h4.029a2.195 2.195 0 01-.071-.131c-.119-.246-.213-.465-.25-.711-.038-.246-.014-.482.026-.753.022-.145.083-.341.16-.573.082-.244.197-.571.348-.998l.002-.006c.153-.431.256-.888.256-1.328z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FramesOfMindIcon;
