import React, { useEffect, createRef } from 'react';

import { PlayIcon } from '@kindlyhuman/component-library';

import { MyProgramDefaultPng } from '../../../assets';

import { ExitIcon } from '../../../components/common/svgs';

type WelcomeVideoProps = {
  videoSrc?: string;
  imageSrc?: string;
  clientLogo?: string;
  handleCloseVideo?: () => void;
};

const WelcomeVideo: React.FunctionComponent<WelcomeVideoProps> = ({
  videoSrc,
  imageSrc,
  clientLogo,
  handleCloseVideo,
}): JSX.Element => {
  const [showVideo, setShowVideo] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);
  const refVideo: any = createRef();

  useEffect(() => {
    const handlePause = () => {
      setCurrentTime(refVideo.current.currentTime);
      setShowVideo(false);
    };

    const videoElement = refVideo.current;
    if (videoElement) {
      videoElement.addEventListener('pause', handlePause);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('pause', handlePause);
      }
      if (refVideo.current) {
        refVideo.current.pause();
      }
    };
  }, [refVideo]);

  useEffect(() => {
    if (showVideo && refVideo.current) {
      refVideo.current.currentTime = currentTime;
      refVideo.current.play();
    }
    if (!showVideo && refVideo.current) {
      refVideo.current.pause();
    }
  }, [showVideo, currentTime]);

  const videoTimeUpdate = () => {
    const currentTimeUpdate = refVideo.current.currentTime;
    const duration = refVideo.current.duration;
    if (currentTimeUpdate === duration) {
      localStorage.setItem('isVideoDisplay', false.toString());
      handleCloseVideo && handleCloseVideo();
    }
  };

  return (
    <div className="h-full w-90 bg-[#e6e6e6] rounded-[10px] flex flex-col">
      <div className="flex justify-between px-3 pt-4">
        <div className="text-2xl font-textaBlack leading-normal">Welcome!</div>
        <ExitIcon
          data-testid="video-card-exit-icon"
          width={24}
          height={24}
          color={'#000'}
          className="cursor-pointer"
          onClick={handleCloseVideo}
        />
      </div>
      <div id="video-placeholder" className="relative flex-1 flex justify-center items-center">
        {!showVideo && (
          <div className="w-[93%] aspect-w-16 aspect-h-9 relative mt-1.5 mb-2.5" onClick={() => setShowVideo(true)}>
            <img
              className="w-full h-full rounded-[10px] object-cover"
              src={imageSrc ? imageSrc : MyProgramDefaultPng}
              alt="play"
            />
            <div className="absolute left-4 bottom-4 flex justify-center items-center">
              <div className="w-9 h-9 bg-white rounded-full flex justify-center items-center cursor-pointer">
                <PlayIcon className="w-3.5 h-3.5" />
              </div>
            </div>
          </div>
        )}
        <div className={`w-[93%] aspect-w-16 aspect-h-9 mt-1.5 mb-2.5 ${!showVideo && 'hidden'}`}>
          <video
            className="h-full w-full rounded-md"
            id="myvideo"
            role="button"
            playsInline
            ref={refVideo}
            onTimeUpdate={videoTimeUpdate}
            controls
            autoPlay
          >
            <source src={videoSrc} />
          </video>
        </div>
      </div>
    </div>
  );
};

export default WelcomeVideo;
