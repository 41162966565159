import { useMutation } from '@tanstack/react-query';
import { axiosPostV3 } from '../api/axios-handler';

export type HubspotEventType = 'onboarding_started' | 'onboarding_completed';

export interface HubspotEvent {
  event: HubspotEventType;
  properties?: {
    [key: string]: string | number | boolean;
  };
}

const postHobspotEvent = async (event: HubspotEvent) => {
  return axiosPostV3('/events/hubspot', event);
};

export const useHubspotEventMutation = () => {
  return useMutation(postHobspotEvent);
};
