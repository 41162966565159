import { FunctionComponent, HTMLAttributes, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';

import { QuestionDropdown, QuestionDropdownProps } from '@kindlyhuman/component-library';

import { User } from '../../../hooks/useUser';
import { CALL_UNITS_CALL_DEFAULT_LENGTH } from '../../scheduling-modal/scheduling-modal';

export interface UserPlanProps extends HTMLAttributes<HTMLDivElement> {
  user: User;
}

const getPlanDetails = (user: User) => {
  const packageProducts = user?.caller_role.active_subscription?.package.package_products;
  const minutesPerUser =
    packageProducts?.find((item: any) => item.configuration?.maximum_minutes_per_user)?.configuration
      ?.maximum_minutes_per_user || 0;
  const memberPayPlan =
    user?.caller_role.active_subscription?.package?.subscriptions?.[0] ||
    user?.caller_role.active_subscription?.package?.package_products?.find(
      (item) => item.product.product_type === 'product' && item.status === 'enabled',
    );
  const planPrice = user?.onMemberPayPlan ? memberPayPlan?.price / 100 : 0;
  const planLength = memberPayPlan?.configuration?.auto_renew_frequency === 'yearly' ? 'year' : 'month';

  if (user.onUnlimitedPlan) {
    return {
      planName: 'Unlimited',
      planDescription: 'Your program covers all calls with Listeners.',
    };
  }
  if (user.onCappedPlan) {
    return {
      planName: user.caller_role.is_call_units
        ? `${minutesPerUser / CALL_UNITS_CALL_DEFAULT_LENGTH} calls / ${planLength}`
        : `${minutesPerUser} minutes / ${planLength}`,
      planPrice: `$${planPrice} / ${planLength}`,
      planDescription: user.caller_role.is_call_units
        ? `${
            minutesPerUser / CALL_UNITS_CALL_DEFAULT_LENGTH
          } calls available to connect with certified Peer Listeners each ${planLength}.`
        : `${minutesPerUser} minutes of talk time with certified Peer Listeners each ${planLength}.`,
    };
  }
  if (user.onMemberPayPlan) {
    const memberPayPlan =
      user.caller_role.active_subscription?.package.subscriptions?.[0] ||
      user.caller_role.active_subscription?.package.package_products?.[0];
    return {
      planName: memberPayPlan?.name,
      planPrice: `$${memberPayPlan?.price / 100} / ${planLength}`,
      planDescription: memberPayPlan?.description,
    };
  }
  // self pay plan, equivalent to user.onSelfPayPlan
  else {
    return {
      planName: 'Pay Per Minute',
      planPrice: `$${(user.caller_role.active_subscription?.package.cost_per_minute_cents! / 100).toFixed(2)} / minute`,
      planDescription: 'Pay only for the minutes you use.',
    };
  }
};

export const UserPlan: FunctionComponent<UserPlanProps> = ({ user, className }) => {
  const { planName, planPrice, planDescription } = useMemo(() => getPlanDetails(user), [user]);

  return (
    <div className={twMerge('space-y-4', className)}>
      <div className="text-gray-800 font-medium space-y-2">
        <p className="text-base leading-normal">{planName}</p>
        <p className="text-xl font-bold leading-loose">{planPrice}</p>
        <p className="text-sm leading-tight">{planDescription}</p>
      </div>
      {!!user?.caller_role.active_subscription?.package.benefits_description && (
        <UserPlanBenefits
          className="p-0 bg-transparent"
          details={user?.caller_role.active_subscription?.package.benefits_description || ''}
        />
      )}
    </div>
  );
};

export const UserPlanBalance: FunctionComponent<UserPlanProps> = ({ user, className }) => {
  // if we're on an unlimited plan we don't have any minutes
  const availableMinutes = user?.caller_role.is_call_units
    ? user?.caller_role.payment_data.available_minutes / CALL_UNITS_CALL_DEFAULT_LENGTH
    : user?.caller_role.payment_data.available_minutes;
  return (
    <div className={twMerge('space-y-4 text-base leading-normal', className)}>
      <p className="text-gray-800 font-bold">Your balance</p>
      <p className="text-stone-500 font-medium">
        {availableMinutes} {user?.caller_role.is_call_units ? 'calls' : 'minutes'}
      </p>
    </div>
  );
};

export const UserPlanRenewsDate: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({ className }) => (
  <div className={twMerge('flex justify-between items-center text-base leading-normal', className)}>
    <div className="text-gray-800 font-bold">Renews on</div>
    <div className="text-stone-500 font-medium">{moment().set('date', 1).add(1, 'M').format('MMMM D, YYYY')}</div>
  </div>
);

export interface UserPlanBenefitsProps extends Omit<QuestionDropdownProps, 'title' | 'body'> {
  details?: string;
}

export const UserPlanBenefits: FunctionComponent<UserPlanBenefitsProps> = ({ details = '', ...props }) => (
  <QuestionDropdown
    title="See benefits"
    body={
      <div
        className="
          [&>ul]:list-disc [&>ul]:list-image-blue_check [&>ul]:pl-[21px] [&>ul]:space-y-2
          [&>ul>li>span]:relative [&>ul>li>span]:top-[-4px]
        "
        dangerouslySetInnerHTML={{
          __html: details,
        }}
      />
    }
    {...props}
  />
);
