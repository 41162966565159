import React from 'react';
import { ROUTE_PATH } from '../../../routes/route-paths';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';

import { ExternalProviderPlan } from '../../../hooks/useExternalProviderPlans';
import {
  PLUSVirtualPrimaryCare,
  TelemedDetailPng,
  CounselingDetailPng,
  PLUSVirtualUrgentCare,
  PLUSPrescriptionBenefit,
  PLUSPediatric,
  PLUSVirtualBehavioralHealth,
  PLUSOnDemandCounseling,
  PLUSOnDemandCounselingEAP,
} from '../../../assets';
import { ResourceTile, ResourceTileSkeleton } from '../../../components/mwa-3.5-redesign/resource-tile';

interface CounselingAndCareProps {
  providerPlan: ExternalProviderPlan;
  providerPlansFetching: boolean;
  clientLogo?: string;
}

const CounselingAndCare: React.FC<CounselingAndCareProps> = ({ providerPlan, providerPlansFetching, clientLogo }) => {
  return (
    <TileContainer
      dataTestId="counseling-and-care"
      title="My Counseling & Care"
      subtitle="Included with your plan"
      isLoading={providerPlansFetching}
      clientLogo={clientLogo ?? clientLogo}
      childrenTiles={
        <>
          {providerPlan.provider_types.includes('primary') && (
            <ResourceTile
              providerPath={ROUTE_PATH.TELEMED}
              image={providerPlan.provider === 'recuro' ? PLUSVirtualPrimaryCare : TelemedDetailPng}
              resourceName={providerPlan.provider === 'recuro' ? '+Virtual Primary Care' : '+Telemed'}
              caption={
                providerPlan.provider === 'recuro'
                  ? 'Find out more about +Virtual Primary Care today'
                  : 'Find out more about +Telemed today.'
              }
            />
          )}
          {providerPlan.provider_types.includes('counseling') && (
            <ResourceTile
              providerPath={ROUTE_PATH.COUNSELING}
              image={providerPlan.provider === 'recuro' ? PLUSVirtualBehavioralHealth : CounselingDetailPng}
              resourceName={providerPlan.provider === 'recuro' ? '+Virtual Behavioral Health' : '+Counseling'}
              caption={`Find out more about ${providerPlan.provider === 'recuro' ? '+Virtual Behavioral Health' : '+Counseling'} now`}
            />
          )}
          {providerPlan.provider_types.includes('crisis') && (
            <ResourceTile
              providerPath={ROUTE_PATH.CRISIS_LITE}
              image={
                !providerPlan?.provider_types.includes('crisis_lite')
                  ? PLUSOnDemandCounselingEAP
                  : PLUSOnDemandCounseling
              }
              resourceName={
                !providerPlan?.provider_types.includes('crisis_lite')
                  ? '+On-Demand Counseling & EAP'
                  : '+On-Demand Counseling'
              }
              caption={'Find out more about +Care now'}
            />
          )}
          {providerPlan.provider_types.includes('urgent') && (
            <ResourceTile
              providerPath={ROUTE_PATH.URGENT}
              image={PLUSVirtualUrgentCare}
              resourceName={'+Virtual Urgent Care'}
              caption={'Find out more about Urgent Care now'}
            />
          )}
          {providerPlan.provider_types.includes('rx') && (
            <ResourceTile
              providerPath={ROUTE_PATH.PRESCRIPTION}
              image={PLUSPrescriptionBenefit}
              resourceName={'+Prescription Benefit'}
              caption={'Medications for Less'}
            />
          )}
          {providerPlan.provider_types.includes('pediatric') && (
            <ResourceTile
              providerPath={ROUTE_PATH.PEDIATRIC}
              image={PLUSPediatric}
              resourceName={'+Pediatric Behavioral Health'}
              caption={'Comprehensive mental wellness support for children and families'}
            />
          )}
        </>
      }
      skeletonTiles={[1, 2].map((int) => (
        <ResourceTileSkeleton key={int} />
      ))}
    />
  );
};

export default CounselingAndCare;
