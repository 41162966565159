import React from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const BackgroundStripe: React.FC = () => {
  const dfMdMedia = useMediaQuery('md');

  return (
    <>
      {dfMdMedia && (
        <div className="w-[5000px] h-[300px] fixed bottom-48 rotate-[-3.72deg] -ml-10 bg-[#005eff]/10 -z-10">
          {/* Leaving the below in place in the event that Jason decides to bring the woman back */}
          {/* {homePage && <img src={KHLady} className='w-[400px] fixed bottom-0 left-24' />} */}
        </div>
      )}
    </>
  );
};
