import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';
import { PLUSPediatric } from '../../../assets';
import getPartnerResourceConfig from '../partner-resource-config';
import { Spinner } from '../../../components/common';
import { useExternalProviderPlan } from '../../../hooks/useExternalProviderPlans';
import { ResourceDetailView } from '../resource-detail';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';
import useAuth from '../../../hooks/useAuth';

export const PediatricPage: React.FC = () => {
  const navigate = useHistory();
  const { user, isLoading: userIsLoading } = useAuth();
  const { data: providerPlans, isLoading: externalPlanLoading } = useExternalProviderPlan();
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;
  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);

  // TODO: add this line in to get the ssoLinkUrl
  // const { data: ssoLinkUrl, isLoading: ssoLinkUrlPending } = useTelemedRedirect(user?.id || 0);
  const ssoLinkUrl = { redirect_url: 'https://member.recurohealth.com' };

  const name = '+Pediatric Behavioral Health';
  const description = 'Comprehensive mental wellness support for children and families.';
  const longDescription = (
    <div className="flex flex-col gap-y-4">
      Your benefit includes virtual access to comprehensive behavioral and mental health support. From school pressures,
      to external stressors, social anxieties, and self-esteem issues – families are dealing with a lot. Pediatric
      Behavioral Health provides coaching programs to help with everyday challenges, counseling for common childhood
      conditions, and a digital platform for support on-the-go to help families thrive. Parental support through
      self-guided and coach-supported skills training is also available for parents and caregivers.
      <div>
        COMMON USES:
        <ul>
          <li>School Pressure</li>
          <li>Stress</li>
          <li>Anxiety</li>
          <li>Bullying</li>
          <li>Confidence</li>
          <li>Self-esteem</li>
          <li>And more</li>
        </ul>
      </div>
      <div>ONLINE ACCESS: Click the link above to schedule a visit online.</div>
      <div>
        PHONE ACCESS: Schedule a visit by phone by calling <div>855-6RECURO (855-673-2876)</div>
      </div>
    </div>
  );

  if (externalPlanLoading || userIsLoading) return <Spinner />;

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={name}
        url={providerPlan?.provider === 'recuro' ? 'https://member.recurohealth.com' : ssoLinkUrl?.redirect_url}
        urlDescription={''}
        phoneNumber={providerPlan?.provider === 'recuro' ? '8006993542' : undefined}
        callButtonText={'Schedule Visit By Phone'}
        ctaButtonText={'Schedule Visit Online'}
        imageUrl={PLUSPediatric}
        tagNames={['Behavioral Health']}
        description={longDescription}
        clientLogoUrl={user?.caller_role.active_subscription?.package?.client?.comms_logo_file_url}
        partnerResourceConfig={partnerResourceConfig}
        recuro={providerPlan?.provider === 'recuro'}
      />
      <BackgroundStripe />
    </div>
  );
};
