import * as yup from 'yup';
import moment from 'moment';

import { User } from '../../hooks/useUser';

import { axiosGet, axiosPost } from '../../api/axios-handler';

import { emailRegex } from '../../components/common/constants';
import { INVALID_DATE } from '../../containers/onboarding/profile-step';
import { normalizePhoneNumber } from '../common/normalize-phone-input';
import { AxiosError } from 'axios';

export interface ProfileFormData {
  first_name: string;
  last_name: string;
  street_address_1: string;
  street_address_2: string;
  city: string;
  state: string;
  zip_code: string;
  mobile_phone: string;
  timezone: string;
  date_of_birth: string;
  gender: string | null;
  pronoun: string | null;
  email_address: string;
  listener_role?: any;
  is_text_compatible_phone: boolean;
  relationship: string | null;
  family: string | null;
  race: string | null;
  spirituality: string | null;
}

export const mobileValid = ({ mobile_phone }: { mobile_phone: string }) =>
  axiosGet(
    '/users/mobile_phone_valid',
    { mobile_phone: mobile_phone },
    {
      cache: {
        interpretHeader: false,
        ttl: 24 * 60 * 60 * 1000, // 1 day
      },
    },
  )
    .then((results: any) => {
      return results;
    })
    .catch((error: any) => {
      console.error(error.response);
    });

const postEmailAddressValidation = (input: any) => () => {
  const url = `/users/email_address_valid`;
  return axiosPost(url, { ...input }, 'v2', {
    cache: {
      methods: ['post'],
      interpretHeader: false,
      ttl: 24 * 60 * 60 * 1000, // 1 day
    },
  });
};

export const generateDefaultValues = (user: User | null): Partial<ProfileFormData> => ({
  first_name: user?.first_name?.trim() || '',
  last_name: user?.last_name?.trim() || '',
  street_address_1: user?.street_address_1?.trim() || '',
  street_address_2: user?.street_address_2 || '',
  city: user?.city?.trim() || '',
  state: user?.state || '',
  zip_code: user?.zip_code || '',
  mobile_phone: user?.mobile_phone ? normalizePhoneNumber(user.mobile_phone.replace('+1', '')) : '',
  timezone: user?.timezone || '',
  date_of_birth:
    user?.date_of_birth && user.date_of_birth !== '0001-01-01'
      ? moment(user.date_of_birth).format('MM-DD-yyyy').toString()
      : '',
  gender: user?.gender || '',
  pronoun: user?.pronoun || '',
  email_address: user?.email_address?.trim() || '',
  is_text_compatible_phone: user?.is_text_compatible_phone,
  relationship: user?.relationship || '',
  family: user?.family || '',
  race: user?.race || '',
  spirituality: user?.spirituality || '',
});

export const collectAddressValidation = {
  street_address_1: yup
    .string()
    .trim()
    .required('Address is required.')
    .test({
      test: (value: string | undefined) => {
        return value ? value.length >= 2 : false;
      },
      exclusive: false,
      message: 'Address must be 2 characters or more',
      name: 'Minimum Address Characters',
    }),
  city: yup
    .string()
    .trim()
    .required('City is required.')
    .test({
      test: (value: string | undefined) => {
        return value ? value.length >= 2 : false;
      },
      exclusive: false,
      message: 'City must be 2 characters or more',
      name: 'Minimum City Characters',
    }),
  state: yup.string().trim().required('State is required.'),
};

export const profileSchema = (userDetails: any, groupId: any) => {
  return yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .required('First Name is required.')
      .test({
        test: (value: string | undefined) => {
          return value ? value.length >= 2 : false;
        },
        exclusive: false,
        message: 'Name must be 2 characters or more',
        name: 'Minimum Characters',
      }),
    last_name: yup
      .string()
      .trim()
      .required('Last Name is required.')
      .test({
        test: (value: string | undefined) => {
          return value ? value.length >= 2 : false;
        },
        exclusive: false,
        message: 'Last name must be 2 characters or more',
        name: 'Minimum Last Name Characters',
      }),
    is_text_compatible_phone: yup.boolean(),
    email_address: yup
      .string()
      .trim()
      .email('Must be valid email')
      .required('Email is required.')
      // @ts-ignore
      .test('checkEmail', async (value?: string, { createError, path }) => {
        if (value === userDetails?.email_address) {
          return true;
        } else if (value && !emailRegex.test(value)) {
          return createError({
            path: 'email_address',
            message: 'Must be valid email',
          });
        } else {
          try {
            await postEmailAddressValidation({
              email_address: value,
              package_code: groupId,
            })();
            return true;
          } catch (error: any) {
            const errorResponse = error as AxiosError<{ description: string; error_code: string }>;
            if (errorResponse?.response?.data?.description?.includes('this plan')) {
              return createError({
                path: 'email_address',
                message:
                  'This email address does not match the email address on file for your group plan. Please use the email address linked to your group plan to continune your enrollment in your group plan.',
              });
            } else if (errorResponse?.response?.data?.description?.includes('use')) {
              return createError({
                path: 'email_address',
                message: 'Email already used, use different email.',
              });
            } else {
              // TODO: make a report somewhere that this happened
              return createError({
                path: 'email_address',
                message: 'Email service not working at the moment. please try again later.',
              });
            }
          }
        }
      }),
    mobile_phone: yup
      .string()
      .trim()
      .required('Phone number is required')
      .test('checkPhone', 'Not a valid phone number.', async (value?: string) => {
        try {
          if (value && value?.length >= 10) {
            await mobileValid({ mobile_phone: value });
          } else {
            return false;
          }
        } catch (error) {
          return false;
        }
        return true;
      }),
    gender: yup.string().required('Gender is required.'),
    date_of_birth: yup
      .string()
      .required('Select DOB')
      .test('Invalid DateTime', 'Invalid date format', (value?: string | undefined) => value !== INVALID_DATE)
      .test('Minimum Age', 'You must be at least 18 years old to use Kindly Human', (value?: string | undefined) => {
        const dob = moment(value, 'MM-DD-yyyy');
        const meetsMinimumAgeRequirement = value ? moment().diff(dob, 'years') >= 18 : false;
        return meetsMinimumAgeRequirement;
      }),
    ...collectAddressValidation,
    zip_code: yup
      .string()
      .trim()
      .required('Zipcode is required.')
      .test({
        test: (value: string | undefined) => {
          return value && value.length !== 5 ? false : true;
        },
        exclusive: false,
        message: 'Use only a 5-digit zip code',
        name: 'Use only a 5-digit zip code',
      }),
    timezone: yup.string().required('Timezone is required.'),
  });
};
