import { Avatar, Button, SelectionChip, Bubble, ExitIconWithBorder, ExitIcon } from '@kindlyhuman/component-library';
import React, { useEffect, useState } from 'react';
import { useTagGroups } from '../../hooks/useTagGroups';
import { usePeerDetail } from '../../hooks/usePeers';
import moment, { Moment } from 'moment-timezone';
import { useCallRequestMutation, useIsEverybodyAvailable } from '../../hooks/useCalls';
import Toast from '../common/PopUpMessage';
import { useQueryClient } from '@tanstack/react-query';
import { morphiiContext } from '../../app';
import { Morphii, MorphiiResult, MorphiiWidget, morphiiDivId } from '../morphii/morphii-widget';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { useLockBody } from '../../hooks/useLockBody';
import { twMerge } from 'tailwind-merge';
import { HorizontalScrollSection } from '../common/horizontal-scroll';
import { ModalBackdrop } from '../common/ModalBackdrop';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import rollbar from '../../containers/rollbar';
import useAuth from '../../hooks/useAuth';

// default pre-configured length of a call units call in minutes
// this is used in a few places around the app in a haphazard manner
export const CALL_UNITS_CALL_DEFAULT_LENGTH = 30;

enum SchedulingModalStep {
  NOT_ENOUGH_MINUTES,
  TIME_STEP,
  NOTE_STEP,
}

interface ScheduleData {
  start: Moment;
  end: Moment;
}

interface Schedule {
  data: ScheduleData[];
  dateLabel: string;
}

const createTimeSplits = (start: Moment, end: Moment): ScheduleData[] => {
  const times = [];

  let currentTime = start;

  while (currentTime.isBefore(end)) {
    times.push({
      start: moment(currentTime),
      // this is a weird moment thing, but since moment's objects are mutable, it's changing current time during this line as well
      end: moment(currentTime.add(30, 'm')),
    });
  }

  return times;
};

export const SchedulingModal: React.FC<{
  open: boolean;
  onExit: (context?: 'scheduled' | 'cancelled' | null) => void;
  listenerId: number;
  listenerAvailableNow: boolean;
  originalRequestId?: number;
}> = ({ onExit, listenerId, listenerAvailableNow, open, originalRequestId: original_request_id = null }) => {
  useLockBody(open);

  const now = moment();

  const { user } = useAuth();
  const { data: peerDetails } = usePeerDetail(listenerId);
  const isNow = useIsEverybodyAvailable(listenerAvailableNow);

  const scheduleCallRequestMutation = useCallRequestMutation();
  const queryClient = useQueryClient();
  // if the user has a timezone, use that, otherwise use the browser's timezone
  const userTimeZone = user?.timezone ? user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [step, setStep] = useState(SchedulingModalStep.TIME_STEP);
  const nowInUserTimeZone = moment().tz(userTimeZone);
  const [time, setTime] = useState<Moment | undefined>(undefined);
  const [date, setDate] = useState<number | undefined>(undefined);
  const [hasMorphii, setHasMorphii] = useState(false);
  const [challengeAreaIds, setChallengeAreaIds] = useState<number[]>();

  useEffect(() => {
    setTime(isNow ? nowInUserTimeZone : undefined);
    setDate(isNow ? nowInUserTimeZone.dayOfYear() : undefined);
  }, [isNow]);

  const sendRequest = async () => {
    if (user && challengeAreaIds) {
      try {
        const morphiiReactionRecordData: Partial<Morphii> | null = await new Promise((res, rej) => {
          morphiiContext.submitByDivId(morphiiDivId, (errors: any, results: MorphiiResult) => {
            if (errors) {
              rej(errors);
            } else {
              res({
                id: results.reaction_record.morphii.id,
                intensity: results.reaction_record.morphii.intensity,
                part_name: results.reaction_record.morphii.part_name,
                display_name: results.reaction_record.morphii.display_name,
              });
            }
          });
        });

        await scheduleCallRequestMutation.mutateAsync(
          {
            listenerRoleId: listenerId,
            payload: {
              call_units_length: user.caller_role.is_call_units ? CALL_UNITS_CALL_DEFAULT_LENGTH : null,
              caller_role_id: user.caller_role_id,
              original_request_id: original_request_id,
              pending_morphii_data: {
                mode: 'pre',
                morphii_id: morphiiReactionRecordData?.id as string,
                morphii_intensity: morphiiReactionRecordData?.intensity as number,
                morphii_part_name: morphiiReactionRecordData?.part_name as string,
                morphii_display_name: morphiiReactionRecordData?.display_name as string,
              },
              perform_backup_requests: true,
              scheduled_at: time?.isSameOrBefore(moment()) ? undefined : time?.toISOString(),
              tag_group_ids: challengeAreaIds,
            },
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['callRequests']);
              Toast.success('Call created.');
              onExit('scheduled');
            },
          },
        );
      } catch (errors: any) {
        if (errors?.[0]?.error?.message === 'Morphii intensity required.') {
          Toast.error('Morphii intensity required.');
        } else {
          queryClient.invalidateQueries(['callRequests']);
          rollbar.error(errors.response?.data.description);
          Toast.error(
            'Oops. It looks like we ran into an issue verifying your schedule. We will work to resolve this shortly, please try back in a little while.',
          );
        }
      }
    } else {
      if (!challengeAreaIds) {
        Toast.error('Please Select a Challenge Area.');
      }
    }
  };

  const schedule: Map<number, Schedule> | undefined = peerDetails?.availabilities.reduce(
    (sch, current, index, array) => {
      const startDate = moment.tz(current.start_date, userTimeZone);
      const endDate = moment.tz(current.end_date, userTimeZone);
      if (endDate.isBefore(moment())) {
        return sch;
      }

      // setting the key as the day of the year to make sorting easy
      const key: number = startDate.dayOfYear();
      if (!sch.get(key)) {
        sch.set(key, {
          dateLabel:
            startDate.dayOfYear() === now.dayOfYear()
              ? `Today, ${moment().format('ddd')}`
              : startDate.format('MMM D, ddd'),
          data: createTimeSplits(startDate, endDate),
        });
      } else {
        sch.get(key)?.data.push(...createTimeSplits(startDate, endDate));
      }

      return sch;
    },
    new Map<number, Schedule>(),
  );
  // make sure to add now date and time to map if isNow
  if (isNow && schedule) {
    if (!schedule.has(nowInUserTimeZone.dayOfYear())) {
      schedule.set(nowInUserTimeZone.dayOfYear(), {
        dateLabel: `Today, ${nowInUserTimeZone.format('ddd')}`,
        data: [
          {
            end: nowInUserTimeZone,
            start: nowInUserTimeZone,
          },
        ],
      });
    } else {
      schedule.get(nowInUserTimeZone.dayOfYear())?.data.push({
        end: nowInUserTimeZone,
        start: nowInUserTimeZone,
      });
    }
  }

  useEffect(() => {
    if (schedule && !isNow && !date) {
      const sortedDays = [...schedule.entries()].map((entry) => entry[0]).sort();
      if (sortedDays[0]) {
        setDate(sortedDays[0]);
      }
    }
  }, [schedule, isNow, date]);

  const isUnlimited = user?.onUnlimitedPlan;
  // if we're on an unlimited plan we don't have any minutes
  const availableMinutes = isUnlimited ? undefined : user?.caller_role.payment_data.available_minutes;
  const has20Minutes = availableMinutes && availableMinutes >= 20;
  const hasPaymentMethod = !!user?.caller_role?.stripe_payment_method_id;

  useEffect(() => {
    if (!isUnlimited && !has20Minutes && !hasPaymentMethod) {
      setStep(SchedulingModalStep.NOT_ENOUGH_MINUTES);
    }
  }, [isUnlimited, has20Minutes, hasPaymentMethod]);

  return (
    <>
      <ModalBackdrop onExit={onExit} />
      <div
        className="
          fixed bottom-0 left-0 right-0 rounded-t-r-15 overflow-y-auto  bg-[#E6E6E6] h-5/6 z-40 w-full
          md:bg-white md:max-w-screen-md md:rounded-lg md:h-[max-content]
          md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2
        "
      >
        <Header availableMinutes={availableMinutes?.toFixed(0)} onExit={onExit} />
        {step === SchedulingModalStep.TIME_STEP && (
          <TimeStep
            userTimeZone={userTimeZone}
            now={nowInUserTimeZone}
            schedule={schedule}
            date={date}
            setDate={setDate}
            time={time}
            setTime={setTime}
            available={peerDetails?.available_now}
            onExit={onExit}
            setStep={setStep}
            listenerImage={peerDetails?.profile_photo_square_file_url}
            listenerName={peerDetails?.user.display_name}
            isNow={!!isNow}
          />
        )}
        {step === SchedulingModalStep.NOTE_STEP && (
          <NoteStep
            hasMorphii={!!hasMorphii}
            setHasMorphii={setHasMorphii}
            sendRequest={sendRequest}
            setStep={setStep}
            listenerImage={peerDetails?.profile_photo_square_file_url}
            listenerName={peerDetails?.user.display_name}
            available={peerDetails?.available_now}
            challengeAreaIds={challengeAreaIds}
            setChallengeAreaIds={setChallengeAreaIds}
          />
        )}
        {step === SchedulingModalStep.NOT_ENOUGH_MINUTES && (
          <NotEnoughMinutes
            ratePerMinute={(user?.caller_role.payment_data.rate_per_minute! / 100).toFixed(2)}
            availableMinutes={availableMinutes?.toFixed(0)}
            onExit={onExit}
          />
        )}
      </div>
    </>
  );
};

const Header: React.FC<{ onExit: () => void; availableMinutes?: string }> = ({ onExit, availableMinutes }) => {
  const { user } = useAuth();
  // TODO: make below reusable
  const isCallUnits = user?.caller_role.is_call_units;
  return (
    <div
      className="
      bg-white p-4 flex justify-between items-center
      md:p-6 md:border-b md:border-neutral-200
    "
    >
      {availableMinutes && (
        <div className="flex flex-nowrap flex-row items-center justify-center gap-3 md:hidden">
          <Bubble>
            {isCallUnits ? parseInt(availableMinutes) / CALL_UNITS_CALL_DEFAULT_LENGTH : availableMinutes}
          </Bubble>
          <div className="flex flex-col text-xs">
            <span>{isCallUnits ? 'Calls' : 'Minutes'}</span>
            <span>Available</span>
          </div>
        </div>
      )}
      <div className="text-gray-800 text-xl font-bold">Schedule a call</div>
      <button data-testid="schedule-close-button" onClick={() => onExit()}>
        <ExitIconWithBorder className="md:hidden" />
        <ExitIcon className="hidden md:block" color="#22282F" />
      </button>
    </div>
  );
};

const TimePill: React.FC<{
  selected?: boolean;
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
}> = ({ children, selected = false, onClick, className }) => (
  <button
    onClick={onClick}
    className={twMerge(
      selected ? 'bg-purple-900 bg-opacity-10 border-violet-950' : 'border-neutral-200 bg-white',
      'h-10 px-7 py-4 rounded-[100px] border justify-center items-center text-sm leading-5 flex-nowrap whitespace-nowrap gap-2 flex',
      className,
    )}
  >
    {children}
  </button>
);

const TimeStep: React.FC<{
  now: Moment;
  isNow: boolean;
  schedule?: Map<number, Schedule>;
  date?: number;
  setDate: React.Dispatch<React.SetStateAction<number | undefined>>;
  time?: Moment;
  setTime: React.Dispatch<React.SetStateAction<Moment | undefined>>;
  setStep: React.Dispatch<React.SetStateAction<SchedulingModalStep>>;
  onExit: () => void;
  listenerImage?: string;
  listenerName?: string;
  available?: boolean;
  userTimeZone: string;
}> = ({
  now,
  setStep,
  onExit,
  listenerImage,
  listenerName,
  available,
  time,
  setTime,
  isNow,
  date,
  schedule,
  setDate,
  userTimeZone,
}) => (
  <>
    <div className="space-y-3  pb-8 overflow-y-auto md:space-y-8 md:max-h-144">
      <StepHeader
        className="p-5 pb-0 md:pt-8 md:px-6"
        available={available}
        listenerImage={listenerImage}
        listenerName={listenerName}
        title={`Connect with ${listenerName} on`}
        subTitle="Select date & time to continue"
      />
      <DateSelection
        className="
          bg-white border-t border-b border-neutral-200  px-4 py-5
          md:bg-transparent md:border-0 md:px-6 md:py-0
        "
        schedule={schedule}
        date={date}
        setDate={setDate}
        setTime={setTime}
      />
      <TimeSelection
        className="
        bg-white border-t border-b border-neutral-200 px-4 py-5
        md:bg-transparent md:border-0 md:px-6 md:py-0
        "
        now={now}
        isNow={isNow}
        date={date}
        schedule={schedule}
        time={time}
        setTime={setTime}
        userTimeZone={userTimeZone}
      />
    </div>
    <div className="mb-12 md:mb-24"></div>
    <TimeStepFooter time={time} date={date} onExit={onExit} setStep={setStep} />
  </>
);

const StepHeader: React.FC<{
  listenerImage?: string;
  listenerName?: string;
  available?: boolean;
  className?: string;
  title?: string;
  subTitle?: string;
}> = ({ listenerImage, listenerName, available, className, title, subTitle }) => {
  const dfMdMedia = useMediaQuery('md');

  return (
    <div className={twMerge('space-y-2 md:space-y-4', className)}>
      <div className="flex justify-start items-center gap-3">
        {listenerImage && (
          <Avatar
            variant={dfMdMedia ? 'x-small' : 'small'}
            image={listenerImage}
            available={available}
            isPeerListener
          />
        )}
        <p className="text-center text-gray-800 text-base font-medium">{listenerName}</p>
      </div>
      <div className="space-y-2">
        <p className="text-gray-800 text-2xl font-bold">{title}</p>
        <p className="text-stone-500 text-sm font-medium">{subTitle}</p>
      </div>
    </div>
  );
};

const DateSelection: React.FC<{
  setTime: React.Dispatch<React.SetStateAction<moment.Moment | undefined>>;
  schedule?: Map<number, Schedule>;
  date?: number;
  setDate: React.Dispatch<React.SetStateAction<number | undefined>>;
  className?: string;
}> = ({ date, setDate, schedule, setTime, className }) => (
  <div className={twMerge('space-y-4', className)}>
    <div className="justify-between items-start flex">
      <p className="text-gray-800 text-base font-bold">Select date</p>
      <p className="text-stone-500 text-base font-medium">{date && schedule?.get(date)?.dateLabel}</p>
    </div>
    <HorizontalScrollSection className="p-0">
      {schedule &&
        [...schedule.keys()].sort().map((scheduleKey) => (
          <div data-testid={`select-date-${scheduleKey}`}>
            <TimePill
              key={scheduleKey}
              selected={date === scheduleKey}
              onClick={() => {
                if (date !== scheduleKey) {
                  setTime(undefined);
                }
                setDate(scheduleKey);
              }}
            >
              {schedule.get(scheduleKey)?.dateLabel}
            </TimePill>
          </div>
        ))}
    </HorizontalScrollSection>
  </div>
);

const TimeSelection: React.FC<{
  now: Moment;
  date?: number;
  schedule?: Map<number, Schedule>;
  time?: Moment;
  isNow: boolean;
  setTime: React.Dispatch<React.SetStateAction<Moment | undefined>>;
  className?: string;
  userTimeZone: string;
}> = ({ time, setTime, schedule, date, now, className, userTimeZone }) => {
  return (
    <div className={twMerge('space-y-8', className)}>
      <div className="justify-between items-start flex">
        <p className="text-gray-800 text-base font-bold">
          Select time {''}
          <span className="font-normal text-sm">
            (
            {userTimeZone
              ? new Intl.DateTimeFormat('en-US', { timeZoneName: 'short', timeZone: userTimeZone })
                  .format()
                  .split(' ')[1]
              : ''}
            )
          </span>
        </p>
        <p className="text-stone-500 text-base font-medium">
          {time?.isSame(now, 'minute') ? 'Now' : time?.format('h:mm a')}
        </p>
      </div>
      <div className="flex flex-wrap gap-2 md:grid grid-cols-3">
        {schedule &&
          date &&
          schedule
            .get(date)
            ?.data.filter((entry) => entry.start.isSameOrAfter(now))
            .sort((date1, date2) => (date1.start.isSameOrBefore(date2.start) ? -1 : 1))
            .map((scheduleData, index) => (
              <div data-testid={`select-time-${index}`}>
                <TimePill
                  key={index}
                  onClick={() => setTime(scheduleData.start)}
                  // this is annoying, but the now time is off by seconds so just doing isSame() won't work for it
                  selected={time !== undefined && scheduleData.start.isSame(time, 'minute')}
                  className="w-28 md:w-auto"
                >
                  {scheduleData.start.isSame(now) ? 'Now' : scheduleData.start.zone(userTimeZone).format('h:mm a')}
                </TimePill>
              </div>
            ))}
      </div>
    </div>
  );
};

const TimeStepFooter: React.FC<{
  time?: Moment;
  date?: number;
  setStep: React.Dispatch<React.SetStateAction<SchedulingModalStep>>;
  onExit: () => void;
}> = ({ setStep, onExit, time, date }) => {
  return (
    <div
      className="
        fixed mt-4 bottom-0 p-4 bg-white w-full border-t border-neutral-200 flex justify-between items-center
        md:p-6
      "
    >
      <Button className="w-42 md:w-auto" onClick={onExit} variant="secondary">
        Cancel
      </Button>
      <Button
        data-testid="schedule-next-button"
        disabled={!time || !date}
        className="w-42 md:w-auto"
        onClick={() => setStep(SchedulingModalStep.NOTE_STEP)}
        variant="primary"
      >
        Next
      </Button>
    </div>
  );
};

const NoteStep: React.FC<{
  hasMorphii: boolean;
  setHasMorphii: React.Dispatch<React.SetStateAction<boolean>>;
  sendRequest: () => Promise<void>;
  setChallengeAreaIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  challengeAreaIds?: number[];
  listenerImage?: string;
  listenerName?: string;
  available?: boolean;
  setStep: React.Dispatch<React.SetStateAction<SchedulingModalStep>>;
}> = ({
  setStep,
  listenerImage,
  listenerName,
  available,
  challengeAreaIds,
  setChallengeAreaIds,
  sendRequest,
  hasMorphii,
  setHasMorphii,
}) => {
  return (
    <>
      <div className="space-y-3 pb-8 overflow-y-auto md:space-y-8 md:max-h-144">
        <StepHeader
          className="p-5 pb-0 md:pt-8 md:px-6"
          listenerImage={listenerImage}
          listenerName={listenerName}
          available={available}
          title="What's up?"
          subTitle={`Let ${listenerName} know how you’re feeling right now`}
        />
        <NoteMorphiiSection hasMorphii={hasMorphii} setHasMorphii={setHasMorphii} />
        <ChallengeAreaSelectionSection challengeAreaIds={challengeAreaIds} setChallengeAreaIds={setChallengeAreaIds} />
      </div>
      <NoteStepFooter
        hasMorphii={hasMorphii}
        sendRequest={sendRequest}
        challengeAreaIds={challengeAreaIds}
        setStep={setStep}
      />
    </>
  );
};

const NoteStepFooter: React.FC<{
  hasMorphii: boolean;
  challengeAreaIds?: number[];
  sendRequest: () => Promise<void>;
  setStep: React.Dispatch<React.SetStateAction<SchedulingModalStep>>;
}> = ({ setStep, challengeAreaIds, sendRequest, hasMorphii }) => {
  const [inProgress, setInProgress] = useState<boolean>(false);

  return (
    <div
      className="
        p-4 bg-white w-full border-t border-neutral-200 flex justify-between items-center
        md:p-6
      "
    >
      <Button
        className="w-42 md:w-auto"
        onClick={() => setStep(SchedulingModalStep.TIME_STEP)}
        variant="secondary"
        disabled={inProgress}
      >
        Back
      </Button>
      <Button
        data-testid="schedule-call-button"
        disabled={Boolean(!challengeAreaIds || challengeAreaIds.length === 0) || !hasMorphii || inProgress}
        className="w-42 md:w-auto"
        onClick={onSubmit}
        variant="primary"
        loading={inProgress}
      >
        Schedule Call
      </Button>
    </div>
  );

  async function onSubmit() {
    try {
      setInProgress(true);

      await sendRequest();
    } finally {
      setInProgress(false);
    }
  }
};

const NoteMorphiiSection: React.FC<{
  hasMorphii: boolean;
  setHasMorphii: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setHasMorphii, hasMorphii }) => (
  <div
    className="
      px-4 py-5 bg-white border-t border-b border-neutral-200 space-y-6
      md:border-0 md:px-6 md:py-0
    "
  >
    <p className="text-gray-800 text-base font-bold leading-normal">How are you feeling today right now?</p>
    <div className="w-screen flex justify-center -mt-15 md:w-auto">
      <MorphiiWidget hasMorphii={hasMorphii} setHasMorphii={setHasMorphii} />
    </div>
  </div>
);

const ChallengeAreaSelectionSection: React.FC<{
  challengeAreaIds?: number[];
  setChallengeAreaIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
}> = ({ challengeAreaIds: challengeAreaId, setChallengeAreaIds }) => {
  const { data: tagGroups } = useTagGroups();

  return (
    <div
      className="
        px-4 py-5 bg-white border-t border-b border-neutral-200 space-y-6
        md:border-0 md:px-6 md:py-0
      "
    >
      <p className="text-gray-800 text-base font-bold leading-normal">I’d like to talk about</p>
      <div className="flex flex-wrap gap-2">
        {tagGroups?.map((tagGroup) => (
          <div data-testid={`select-challenge-area-${tagGroup.name}`}>
            <SelectionChip
              className="cursor-pointer"
              key={tagGroup.id}
              onSelect={() =>
                setChallengeAreaIds((selectedTagGroups) =>
                  selectedTagGroups?.includes(tagGroup.id)
                    ? selectedTagGroups.filter((prevTagGroup) => prevTagGroup !== tagGroup.id)
                    : [tagGroup.id, ...(selectedTagGroups ?? [])],
                )
              }
              // @ts-ignore
              variant={challengeAreaId?.includes(tagGroup.id) ? tagGroup.key : undefined}
            >
              {tagGroup.name}
            </SelectionChip>
          </div>
        ))}
      </div>
    </div>
  );
};

const NotEnoughMinutes: React.FC<{
  onExit: () => void;
  availableMinutes?: string;
  ratePerMinute: string | number;
}> = ({ onExit, availableMinutes, ratePerMinute }) => (
  <div className="p-5 space-y-11 md:py-8 md:px-6">
    <div className="space-y-4">
      <div className="text-gray-800 text-2xl font-bold font-manrope leading-loose">Low call minutes available</div>
      <div className="flex flex-col">
        <div className="text-stone-500 text-sm font-medium font-manrope leading-tight flex flex-col gap-4">
          <span>
            To schedule a call, you need at least 20 minutes of credit or a payment method on file. <br />
          </span>
          <span>
            We will use your available credit minutes, and then charge your payment method for any additional minutes if
            necessary.
          </span>
          <br />
        </div>
        <div className="text-stone-500 text-sm font-bold font-manrope leading-tight">
          Rate per minute: ${ratePerMinute}
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-4 items-center">
      <Link className="w-5/6" to={ROUTE_PATH.PAYMENT}>
        <Button className="w-full" variant="primary">
          Add Payment Method
        </Button>
      </Link>
      <Button className="w-5/6" variant="secondary" onClick={onExit}>
        Cancel
      </Button>
    </div>
  </div>
);
